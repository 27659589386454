import "./src/styles/global.scss"

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const success = urlParams.get("success")

    const lng = localStorage.getItem("@wappsLng")

    if (lng) {
      window.sessionStorage.setItem("search", queryString)
    } else {
      localStorage.setItem("@wappsLng", "ua")
    }

    if (success) window.sessionStorage.setItem("search", queryString)
  }
}
